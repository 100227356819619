import {Button, Col, ConfigProvider, Divider, Layout, Row, theme, Typography} from "antd";
import Debug from "./components/debug/debug";
import {Content, Header} from "antd/es/layout/layout";
import DictaphoneV2 from "./components/debug/dictaphone-v2";
import SpeechSynthesis from "./components/debug/speech-synthesis";
import {useState} from "react";

function App() {
    const {
        token
    } = theme.useToken();

    const [currentWord, setCurrentWord] = useState('');

    const onWordChange = (word) => {
        setCurrentWord(word);
    }

    return (
    <div className="App">
        <Layout
        >
            <Header
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <ConfigProvider
                    theme={{
                        algorithm: theme.darkAlgorithm,
                    }}
                >
                    <Typography.Text strong>Boring Counting</Typography.Text>

                    <Button target={"_blank"} href="https://github.com/yishi-projects/boring-counting">Github</Button>
                </ConfigProvider>
            </Header>

            <Row>
                <Col span={12} offset={6} style={{
                    padding: '4rem',
                    minHeight: '100vh',
                }}>
                    <Content
                        style={{
                            padding: '24px 0',
                            background: token.colorBgContainer,
                        }}
                    >
                        <Debug></Debug>

                        {/*<Dictaphone></Dictaphone>*/}
                        <DictaphoneV2 onWordChange={onWordChange}></DictaphoneV2>

                        <Divider />

                        <SpeechSynthesis text={currentWord}></SpeechSynthesis>
                    </Content>
                </Col>
            </Row>
        </Layout>
    </div>
  );
}

export default App;
