import { Button } from 'antd';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

const DictaphoneV2 = (props) => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [recognizing, setRecognizing] = useState(false);
    const [errorReason, setErrorReason] = useState('');
    const [interimTranscript, setInterimTranscript] = useState('');
    const [finalTranscript, setFinalTranscript] = useState('');

    const lang = 'cmn-Hans-CN';
    const words = useMemo(() => {
        return [
            // '一个人',
            // '两条船',
            // '三个朋友',
            // '四个馒头',
            // '五个凳子',
            // '六个老师',
            // '七个小矮人',
            // '八个神仙',
            '九个苹果',
            '八个神仙',
            '九个苹果',
            '九个苹果',
            '九个苹果',
            '九个苹果',
            '九个苹果',
            '早上好',
            '电脑没电了',
            '我的手机还在充电',
            '那些女孩子在跳舞',
            '股票还在上涨',
            '人在恋爱中会迷失自我',
            '白马在草原上奔驰',
            '我喜欢北京的天气',
            '大自然总是给我们惊喜',
        ];
    }, []);

    // const lang = 'en-US';
    // const words = [
    //     'there is a lady',
    //     'who can help me',
    //     'why open the door',
    // ];
    //
    // const lang = 'ja-JP';
    // const words = [
    //     '日本語',
    //     'そこには',
    //     '中国語',
    //     '東京',
    // ];

    const _setCurrentIndex = useCallback((index) => {
        setCurrentWordIndex(index);

        if (props?.onWordChange) {
            props.onWordChange(words[currentWordIndex]);
        }
    }, [currentWordIndex, props, words]);

    useEffect(() => {
        console.log('useEffect');
        _setCurrentIndex(0);
    }, [_setCurrentIndex]);


    const recognition = new webkitSpeechRecognition();
    recognition.interimResults = true;
    recognition.continuous = false;
    recognition.lang = lang;

    recognition.onstart = function() {
        setRecognizing(true);
    };

    recognition.onerror = function(event) {
        if (event.error === 'no-speech') {
            setErrorReason('info_no_speech');
        }
        if (event.error === 'audio-capture') {
            setErrorReason('info_no_microphone');
        }
        if (event.error === 'not-allowed') {
            setErrorReason('not_allowed');
        }
    };

    recognition.onend = function() {
        setRecognizing(false);

        // recognition.start(); // Start Again.
    };

    recognition.onresult = function(event) {
        console.log('onresult', '-------');
        let _finalTranscript = '';
        let _interimTranscript = '';

        setInterimTranscript('');
        setFinalTranscript('');
        _interimTranscript = '';

        if (typeof(event.results) == 'undefined') {
            recognition.onend = null;
            recognition.stop();
            return;
        }

        for (let i = event.resultIndex; i < event.results.length; ++i) {
            if (event.results[i].isFinal) {
                setFinalTranscript(finalTranscript + event.results[i][0].transcript);
                _finalTranscript += event.results[i][0].transcript;
            } else {
                setInterimTranscript(interimTranscript + event.results[i][0].transcript);
                _interimTranscript += event.results[i][0].transcript;
            }
        }

        console.log('finalTranscript', finalTranscript);
        console.log('interimTranscript', interimTranscript);
        console.log('_finalTranscript', _finalTranscript);
        console.log('_interimTranscript', _interimTranscript);
        console.log('currentWordIndex', currentWordIndex);
        console.log('words[]', words[currentWordIndex]);

        if (checkWordEqual(_finalTranscript)) {
            console.log('will move next');
            setFinalTranscript('')
            setInterimTranscript('')
            moveToNextWord();
        } else {
            console.log('will not move to next one.')
        }
    };


    const startListening = () => {
        recognition.start();
    }

    const moveToNextWord = () => {
        console.log('moveToNextWord', currentWordIndex);
        // Reset
        if (currentWordIndex + 1 >= words.length) {
            console.log('reset index to 0.');
            // setCurrentWordIndex(0);
            _setCurrentIndex(0);
        } else {
            console.log(`increase index to ${currentWordIndex+1}.`);
            // setCurrentWordIndex(currentWordIndex + 1);
            _setCurrentIndex(currentWordIndex + 1);
        }
    }

    const checkWordEqual = (word: string) => {
        return word === words[currentWordIndex];
    }

    // @ts-ignore
    return (
        <>
            <Button onClick={startListening}>Start</Button>
            <div>
                <p>lang: {lang}</p>
                <p>currentWord: {words[currentWordIndex]}</p>
                <p>recognizing: {recognizing ? 'YES' : 'NO'}</p>
                <p>errorReason: {errorReason}</p>
                <p>interimTranscript: {interimTranscript}</p>
                <p>finalTranscript: {finalTranscript}</p>
            </div>
        </>
    );
};
export default DictaphoneV2;