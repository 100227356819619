import React from "react";
import {useState} from "react";
// import { useSpeechSynthesis } from "react-speech-kit";
// import styles from "./speech-synthesis.css";
import './speech-synthesis.css';
import {Button} from "antd";
import useSpeechSynthesis from "../../services/useSpeechSynthesis";

const SpeechSynthesis = (props) => {
    // const [text, setText] = useState('I am a robot');
    const [selectedVoice, setSelectedVoice] = useState(null);

    const {speak, voices } = useSpeechSynthesis();

    const onSelectLang = (lang) => {
        setSelectedVoice(lang);
    }

    const onPlay = (t) => {
        console.log('onPlay', t);
        speak({ text: t, voice: selectedVoice, rate: 0.75, pitch: 1 });
    }

    return (
        <>
            <div>
                <div>Lang: {selectedVoice?.lang}</div>
                <div><Button onClick={() => onPlay(props?.text)}>Play [{props?.text}]</Button></div>
            </div>
            <div>
                {voices.filter((item) => item.lang.startsWith('zh-')).map((voice: SpeechSynthesisVoice) => {
                    console.log('voice', voice);
                    return (
                        <div key={voice.voiceURI} onClick={() => onSelectLang(voice)} className={voice?.voiceURI === selectedVoice?.voiceURI ? 'selected' : ''} style={{'border': '1px solid red', margin: '1rem', padding: '1rem'}}>
                            <div>lang: {voice.lang}</div>
                            <div>name: {voice.name}</div>
                            <div>voiceURI: {voice.voiceURI}</div>
                            <div>localService: {voice.localService ? 'YES': 'NO'}</div>
                            <div>default: {voice.default ? 'YES': 'NO'}</div>
                        </div>
                    );
                })}
            </div>
        </>
    )
}

export default SpeechSynthesis;