import {Col, Divider, Form, InputNumber, Row, Statistic} from "antd";
import {useState} from "react";
import React from 'react';
import {Content} from "antd/es/layout/layout";
import {numberToWordsChinese} from "../../services/num";

const Debug = () => {
    const [numWords, setNumWords] = useState('');
    const [num, setNum] = useState(1720);

    const onNumChange = (value: number | null) => {
        if (value && +value > 0) {
            setNum(value);

            const words = numberToWordsChinese(value);

            setNumWords(words);
        }
    }

    // onNumChange(num);

    return (
        <Content style={{
            padding: '1rem',
        }}>
            <Row gutter={16}>
                <Col span={12}>
                    <Form
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            "num": num,
                        }}
                        layout="vertical"
                    >
                        <Form.Item
                            name="num"
                            label="Num"
                        >
                            <InputNumber min={0} onChange={onNumChange}/>
                        </Form.Item>

                    </Form>
                </Col>
                <Col span={12}>
                    <Statistic title="Words" value={numWords} />
                </Col>

                <Divider />
            </Row>
        </Content>
    );
}

export default Debug;